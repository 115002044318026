<template>
  <div>
    <v-expansion-panels v-model="panel" flat multiple class="ma-4 ms-1">
      <v-expansion-panel :class="`${outlined ? 'outlined' : ''}`">
        <v-progress-linear
          v-if="showLoading"
          indeterminate
          color="primary"
        ></v-progress-linear>
        <v-expansion-panel-header
          class="grey--text text--darken-2 font-weight-medium text-body-1"
        >
          <div class="d-flex flex-column">
            {{ $t("common.configSecondaryEmail") }}
            <small>{{ $t("common.sendEmailAs") }}</small>
          </div>
          <v-spacer />
          <v-play-video-button
            :title="$t('common.sendEmailAs')"
            id_youtube_video="ygPflAvriSA"
            justify="end"
            class="mr-2"
          />
        </v-expansion-panel-header>

        <v-expansion-panel-content
          :class="`pb-0 ${overlayScopes ? 'pa-3' : ''}`"
          style="position: relative"
        >
          <v-card-text class="mx-0 pt-0 px-0">
            {{ $t("common.configSecondaryEmailDescription") }}
          </v-card-text>

          <v-simple-table class="mb-4">
            <template v-slot:default>
              <tbody>
                <tr v-for="item in items" :key="item.email">
                  <td v-if="item.mainUser == true && item.name == ''">
                    {{ user.name }} ({{ item.email }})
                  </td>
                  <td v-else-if="item.name != ''">
                    {{ item.name }} ({{ item.email }})
                  </td>
                  <td v-else>{{ item.email }}</td>

                  <td class="pl-8" v-if="item.default == true" align="end">
                    <span class="mr-4">{{ $t("common.defaultEmail") }}</span>
                  </td>
                  <td v-else align="end">
                    <v-btn
                      text
                      small
                      class="end-text"
                      outlined
                      color="accent"
                      dark
                      @click="
                        (showDialogMainSendAs = true),
                          ((email_to_update = item.email),
                          (current_signature = item.signature))
                      "
                      >{{ $t("action.makeDefault") }}</v-btn
                    >
                    <v-dialog
                      :retain-focus="false"
                      v-model="showDialogMainSendAs"
                      width="500"
                      @keydown.esc="$emit(`close`)"
                      @click:outside="$emit(`close`)"
                      content-class="elevation-0"
                      overlay-opacity="0.1"
                    >
                      <v-card>
                        <h2 class="title py-3 px-6">
                          {{ $t("common.confirmChange") }}
                        </h2>
                        <v-card-text>{{
                          $t("common.confirmAction")
                        }}</v-card-text>
                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="accent"
                            text
                            @click="showDialogMainSendAs = false"
                            >{{ $t("action.cancel") }}</v-btn
                          >
                          <v-btn
                            color="#311F50"
                            text
                            @click="
                              updateSendAs(email_to_update),
                                (showDialogMainSendAs = false)
                            "
                            >{{ $t("action.confirm") }}</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </td>

                  <td v-if="!item.mainUser" align="end">
                    <v-btn
                      text
                      small
                      class="ma-2"
                      outlined
                      color="accent"
                      dark
                      @click="
                        (showDialogModifyItem = true),
                          ((email_to_modify = item.email),
                          (modify_email_is_default = item.default),
                          (current_signature = item.signature))
                      "
                      >{{ $t("action.edit") }}</v-btn
                    >

                    <v-dialog
                      :retain-focus="false"
                      v-model="showDialogModifyItem"
                      width="500"
                      @keydown.esc="$emit(`close`)"
                      @click:outside="$emit(`close`)"
                      content-class="elevation-0"
                      overlay-opacity="0.1"
                    >
                      <v-card>
                        <h2 class="title py-3 px-6">
                          {{ $t("common.confirmModify") }}
                        </h2>
                        <v-card-text class="pb-2">
                          {{ $t("common.enterNameFor") }} {{ email_to_modify }}.
                          <br />
                          Ex.: Comercial, Contato, Financeiro…
                          <v-row>
                            <v-col cols="12" class="pt-3">
                              <v-text-field
                                class="py-0"
                                v-model="newItem.name"
                                :label="$t('common.name')"
                                color="#311F50"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          {{ $t("common.descriptionConfirmModification") }}
                          {{ email_to_modify }}?
                        </v-card-text>
                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="secondary"
                            text
                            @click="showDialogModifyItem = false"
                            >{{ $t("action.cancel") }}</v-btn
                          >
                          <v-btn
                            color="accent"
                            text
                            :disabled="!newItem.name"
                            @click="
                              updateSendAs(
                                email_to_modify,
                                true,
                                modify_email_is_default
                              ),
                                (showDialogModifyItem = false)
                            "
                            >{{ $t("action.confirm") }}</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </td>

                  <td v-else align="end">
                    <v-btn text small disabled class="ma-2">{{
                      $t("action.edit")
                    }}</v-btn>
                  </td>

                  <td v-if="!item.mainUser" align="end">
                    <v-btn
                      text
                      small
                      class="ma-2"
                      outlined
                      color="accent"
                      dark
                      @click="
                        (showDialogDeleteItem = true),
                          (email_to_delete = item.email)
                      "
                      >{{ $t("action.delete") }}</v-btn
                    >

                    <v-dialog
                      :retain-focus="false"
                      v-model="showDialogDeleteItem"
                      width="500"
                      @keydown.esc="$emit(`close`)"
                      @click:outside="$emit(`close`)"
                      content-class="elevation-0"
                      overlay-opacity="0.1"
                    >
                      <v-card>
                        <h2 class="title py-3 px-6">
                          {{ $t("common.confirmDeletion") }}
                        </h2>
                        <v-card-text
                          >{{ $t("common.confirmDeletionDescription") }}
                          {{ email_to_delete }}?</v-card-text
                        >
                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="secondary"
                            text
                            @click="showDialogDeleteItem = false"
                            >Cancelar</v-btn
                          >
                          <v-btn
                            color="#311F50"
                            text
                            @click="
                              deleteSendAs(email_to_delete),
                                (showDialogDeleteItem = false)
                            "
                            >{{ $t("action.confirm") }}</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </td>

                  <td v-else align="end">
                    <v-btn text small disabled class="ma-2">{{
                      $t("action.delete")
                    }}</v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-tooltip :disabled="userHasGmail" bottom>
            <template v-slot:activator="{ on, attrs }">
              <div style="width: fit-content" v-bind="attrs" v-on="on">
                <v-btn
                  :color="`${isOnboardingPage ? 'primarySuite' : 'accent'}`"
                  @click="dialogAddUser = true"
                  :disabled="showLoading || !userHasGmail"
                  class="mt-0 elevation-0 text-none font-weight-medium text-body-2 white--text"
                >
                  {{ $t("common.addEmailAddress") }}
                </v-btn>
              </div>
            </template>
            <span v-if="!userHasGmail">
              O serviço de e-mail deste usuário não está ativo.
            </span>
          </v-tooltip>

          <!-- DIALOG ADC NOVO EMAIL -->
          <ApplySendAs
            :show="dialogAddUser"
            :users="[user]"
            :loading="showLoading"
            @loading="showLoading = $event"
            @then="thenCreateSendAs()"
            @close="cancelBtn()"
          />
          <!-- MODAL DE FEEDBACK -->
          <HeaderDialog
            v-if="
              renewFeedbackOnSecondTime(currentUser.feedbacks.send_as_email) &&
              checkShowFeedback('send_as_email')
            "
            ref="form"
            id="user_feedback"
            title="Deixe seu feedback sobre a funcionalidade"
            :show="showUserFeedback"
            width="600"
            :persistent="false"
            custom-footer
            @close="closeFeedback"
          >
            <template v-slot:body>
              <v-row class="mx-0">
                <v-col class="mx-0">
                  <FeedbackRating
                    action="send_as_email"
                    showDismiss
                    @close="closeFeedback"
                  />
                </v-col>
              </v-row>
            </template>
          </HeaderDialog>
          <!-- FIM DIALOG-->
          <!-- OVERLAY FOR SCOPES MISSING  -->
          <OverlayScopes
            :overlay="overlayScopes"
            :functionality="$t('common.configSecondaryEmail')"
            dense
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import HeaderDialog from "@/components/base/HeaderDialog.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";
import FeedbackRating from "@/components/forms/FeedbackRating.vue";
import ApplySendAs from "@/components/sign/send_as/ApplySendAs.vue";
import {
  renewFeedbackOnSecondTime,
  setNotShowFeedbackToday,
  checkShowFeedback,
} from "@/helpers/services/utils";
import OverlayScopes from "../general/OverlayScopes.vue";

export default {
  name: "SendAs",
  components: { HeaderDialog, FeedbackRating, ApplySendAs, OverlayScopes },
  props: {
    user: { type: Object, default: () => {} },
    outlined: { type: Boolean, default: false },
    getUserSendAs: { type: Boolean, default: false },
    isPanelOpened: { type: Boolean, default: false },
  },
  data() {
    return {
      panel: [this.isPanelOpened ? 0 : 1],
      showDialogMainSendAs: false,
      showLoading: false,
      valid: true,
      sendas_id_user: null,
      sendas_email: null,
      get_sendas_id_user: null,
      delete_sendas_id_user: null,
      delete_sendas_email: null,
      newItem: {
        name: "",
        email: "",
        default: "",
      },
      headers: [
        { text: "E-mail", value: "email" },
        { text: "Padrão", value: "default" },
        { text: "", value: "actions" },
      ],
      items: [],
      treatAsAlias: false,
      dialogAddUser: false,
      showDialogDeleteItem: null,
      showDialogModifyItem: null,
      radioRuleDefaultAddress: "replyAsReceivedEmail",
      email_to_delete: null,
      email_to_update: null,
      email_to_modify: null,
      modify_email_is_default: false,
      current_signature: null,
      signature_email: null,
      disabled: "disabled",
      showUserFeedback: false,
      overlayScopes: false,
    };
  },

  computed: {
    ...mapGetters(["currentUser", "token", "userSendAs"]),

    userHasGmail() {
      return this.user ? this.user.is_gmail_enabled : false;
    },

    isOnboardingPage() {
      if (this.$route.name === "Onboarding") return true;
      return false;
    },
  },

  methods: {
    ...mapMutations(["setSnackBar"]),
    ...mapActions(["completeMission", "getSendAsByUser"]),

    renewFeedbackOnSecondTime,
    setNotShowFeedbackToday,
    checkShowFeedback,

    async getSendAs() {
      this.disabled = "disabled";
      this.showLoading = true;

      const result = await this.getSendAsByUser(this.user);
      if (result === "INVALID_GOOGLE_ACCESS_TOKEN") {
        this.overlayScopes = true;
        this.showLoading = false;
        this.disabled = true;
        return;
      }
      let items = this.userSendAs(this.user.id_google);

      if (items == "error") {
        this.disabled = "disabled";
      } else {
        this.$root.$emit("eventUpdateSendAs", items);
        this.disabled = false;
        this.items = items;
      }
      this.showLoading = false;
    },

    thenCreateSendAs() {
      this.items = [...this.userSendAs(this.user.id_google)];
      if (!this.isOnboardingPage) {
        this.showUserFeedback = true;
        this.completeMission("create_secondary_email");
      }
      this.cancelBtn();
    },

    updateSendAs(email_to_update, modify = false, is_default) {
      let _this = this;
      _this.showLoading = true;

      let url = process.env.VUE_APP_API_BASE_URL + "/user/sendas/update";
      let params = {
        email: email_to_update,
        id_user: this.user.id_google,
        signature: _this.current_signature,
        is_default: true,
      };
      if (modify === true) {
        params.name = this.newItem.name;
        params.is_default = is_default;
      }
      const auth = { headers: { Authorization: this.token } };

      this.$axios
        .patch(url, params, auth)
        .then(() => {
          _this.getSendAs();
          this.setSnackBar({
            show: true,
            message: successMessages.apply_24hours,
            color: "success",
          });
          this.cancelBtn();
        })
        .catch((err) => {
          console.error("updateSendAs()", err);
          _this.showLoading = false;
          this.setSnackBar({
            show: true,
            message: errorMessages.unknown,
            color: "error",
          });
        });
    },

    deleteSendAs: function (email_to_delete) {
      let _this = this;
      _this.showLoading = true;

      let url = process.env.VUE_APP_API_BASE_URL + "/user/sendas";

      const data = { email: email_to_delete, id_user: this.user.id_google };

      const headers = { Authorization: this.token };

      this.$axios
        .delete(url, {
          headers,
          data,
        })
        .then(() => {
          _this.getSendAs();
          _this.showDialogDeleteItem = false;
          this.setSnackBar({
            show: true,
            message: successMessages.delete_24hours,
            color: "success",
          });
        })
        .catch((err) => {
          console.log("deleteSendAs()", err);
          _this.showLoading = false;
          this.setSnackBar({
            show: true,
            message: errorMessages.unknown,
            color: "error",
          });
        });
    },

    validateEmail() {
      if (this.newItem.email.length > 0) {
        if (/.+@.+\..+/.test(this.newItem.email)) {
          this.valid = true;
          return true;
        } else {
          this.valid = false;
          return "E-mail inválido";
        }
      } else {
        this.valid = false;
        return true;
      }
    },

    closeFeedback() {
      this.showUserFeedback = false;
      this.setNotShowFeedbackToday("send_as_email");
    },

    cancelBtn() {
      this.dialogAddUser = false;
      this.newItem = {
        name: "",
        email: "",
      };
    },
  },

  watch: {
    user() {
      if (this.userHasGmail) {
        this.showLoading = true;
        this.getSendAs();
      }
    },
    getUserSendAs() {
      if (this.getUserSendAs) {
        this.getSendAs();
      }
    },

    panel() {
      if (this.userHasGmail && !this.userSendAs(this.user.id_google)?.length) {
        this.getSendAs();
        this.userSendAs(this.user.id_google);
      }
    },
  },

  async mounted() {
    if (
      !this.userSendAs(this.user.id_google)?.length &&
      this.userHasGmail &&
      this.isPanelOpened
    )
      await this.getSendAs();
    else this.items = this.userSendAs(this.user.id_google);
  },
};
</script>

<style scoped>
.defaultBtn {
  color: #311f50;
}
</style>
